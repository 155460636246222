/* App.css */
@media (min-width: 1024px) {

.product_types {
  display: flex;
  align-items: space-between;
  background: rgb(162, 156, 151);
  background: linear-gradient(
    260deg,
    rgba(162, 156, 151, 1) 9%,
    rgba(86, 81, 78, 1) 100%
  );

  background: linear-gradient(to right, #ffffff, #f0f0f0);
}
.product_header {
  font-size: 2.2rem; /* Custom font size */
  
  font-family: "Bona Nova SC", serif;
  text-transform: uppercase;
  font-weight: bold; /* Custom font weight */
  color: rgb(87, 33, 1);/* Custom color */
  letter-spacing: 2px; /* Custom letter spacing */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Text shadow */
  
  padding: 20px; /* Padding */
  padding-left: 210px;
  /* transition: all 0.3s ease;  */
  /* Transition for hover effects */
}
.product_types_map {
  display: flex;
  align-items: center;
  margin-left: 250px;
}
.product-item {
  margin: 0 10px; /* Adjust the spacing between products if necessary */
  color: rgb(87, 33, 1);
  /* font-family: "Oleo Script", system-ui; */
  font-family: "Bona Nova SC", serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.product-separator {
  margin: 0 5px; /* Adjust the spacing around the pipe symbol if necessary */
  color: rgb(87, 33, 1);
  font-size: 20px;
}

.product-item.active {
  padding-bottom: 3px;
  border-bottom: 3px solid  rgb(87, 33, 1);;
}
}


/* For Mobile */
@media (max-width: 896px) {


  .product_types {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    background: linear-gradient(to right, #ffffff, #f0f0f0);
  }
  .product_header {
    font-size: 1.2rem; /* Custom font size */
    /* font-family: "Josefin Sans", sans-serif; */
    /* font-family: "Oleo Script", system-ui; */
    font-family: "Bona Nova SC", serif;
    text-transform: uppercase;
    font-weight: bold; /* Custom font weight */
    color: rgb(87, 33, 1); /* Custom color */
    letter-spacing: 2px; /* Custom letter spacing */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Text shadow */
    /* background: linear-gradient(to right, #ff7e5f, #feb47b); Gradient background */
    /* -webkit-background-clip: text; Background clip for text */
    /* Padding */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Box shadow */
    transition: all 0.3s ease; /* Transition for hover effects */
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin: 0 5px;

  }
  .product_types_map {
    display: flex;
    align-items: center;
  }
  .product-item {
    margin: 0 5px; /* Adjust the spacing between products if necessary */
    color: rgb(87, 33, 1);
    /* font-family: "Oleo Script", system-ui; */
    font-family: "Bona Nova SC", serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    
  }
  
  .product-separator {
    margin: 0 5px; /* Adjust the spacing around the pipe symbol if necessary */
    color:  rgb(87, 33, 1);
    font-size: 20px;
  }
  
  .product-item.active {
    padding-bottom: 1px;
    border-bottom: 3px solid  rgb(87, 33, 1);
  }

}