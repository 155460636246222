@media (min-width: 1024px) {
  .carousel-container {
    /* width: 80%; */
    margin: 0 auto;
    /* padding-top: 50px; */
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .carousel-dots {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    right: 750px;
    bottom: 3px;
  }

  .carousel-inner div {
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* background-size: cover; */
    overflow: hidden;
    position: "relative";
    background-position: "calc(100% + 1px) center";
    height: 100vh;
    width: 100%;
    /* Shadow */
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); */
    /* Gradient from black with 60% opacity to transparent */
    z-index: 1; /* Ensure the gradient is above the image */
  }

  .carousel-item {
    width: 150px;
    transition: none !important;
  }
  
  
  .dot{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
  
  }

  .dot.active {
    background-color: #e7b81f;
    height:  12px;
    width: 12px;
  }

  .landing-text {
    position: absolute;
    /* background-color: rgba(255, 255, 255, 0.1); */
    top: 250px;
    z-index: 100;
    color: white;
    padding: 50px;
    font-size: 12px;
    line-height: 1;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100ch;
    word-spacing: 8px;
    padding-left: 8px;
    font-family: "Zain", sans-serif;
    color:rgb(189, 89, 89); /* Dark maroonish color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bolder;
  margin-left: 50px;
  }
}

/* For mobile */
@media (max-width: 896px) {
  .carousel-container {
    /* width: 80%; */
    margin: 0 auto;
  }
  .carousel-dots {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    right: 750px;
    bottom: 3px;
  }

  .carousel-inner {
    display: flex;
    align-items: center;
  }
  .carousel-inner div {
    /* object-fit: cover; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-size: contain;
    overflow: hidden;
    /* position: 'relative'; */
    background-size: 100% 30vh;
    background-position: center;
    background-repeat: no-repeat;
    /* background-position: 'calc(100% + 1px) center'; */
    height: 30vh;
    width: 100%;
    /* Shadow */
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)); */
    /* Gradient from black with 60% opacity to transparent */
    z-index: 1; /* Ensure the gradient is above the image */
  }

  .carousel-item {
    width: 150px;
    transition: none !important;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
  }

  .dot.active {
    background-color: #e7b81f;
  }

  .landing-text {
    position: absolute;   
    top: 70px;
    z-index: 100;
    color: white;
    line-height: 0.7;
    /* white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 150ch; */
    /* max-width: 140ch; */
    word-spacing: 3px;
    padding-left: 2px;
    font-family: "Zain", sans-serif;
    color:rgb(189, 89, 89); /* Dark maroonish color */
     text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-weight: 400;
    
  }
  .landing-text>h1{
    font-size: 16px;
     max-width: 30ch;
     word-spacing: 3px;
     white-space: pre-wrap;
    word-wrap: break-word; 

  }
}
