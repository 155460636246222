@media (min-width: 1024px) {
.about_us{
    background-color:  rgb(87, 33, 1);;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 420px;
    background: linear-gradient(to right, #8b7d7b, #d2b48c);
}
.about_img{
    
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:  290px;
    
    width: 541px;
        margin-left: 106px;
    align-items: center,
}
.about_us_text{
    
        font-size: 19px;
        line-height:1.5;
        color: #333;
        padding: 10px;
        margin: 20px 0;
        white-space: pre-wrap; /* Preserve whitespace */
        word-wrap: break-word; /* Break words as necessary */
        max-width: 60ch;
        word-spacing: 8px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        margin-right: 63px;
        
      
}
}
/* For Mobile */
@media (max-width: 896px) {

    .about_us{
        background-color:  rgb(87, 33, 1);;
        height: 420px;
        background: linear-gradient(to right, #8b7d7b, #d2b48c);
    }
    .about_img{
        
        background-color: transparent;
        background-size: contain;
        /* background-position: center; */
        background-repeat: no-repeat;
        height:  50%;
        width: 100%;
        align-items: center,
    }
    .about_us_text{
        
        font-size: 12px;
        line-height: 1.2;
        color: #333;
        white-space: pre-wrap;
        word-wrap: break-word;
        max-width: 160ch;
        word-spacing: 10px;
        padding-left: 8px;
        font-family: "PT Sans", sans-serif;
            
          
    }
}