/* .container-fluid {
  background-color: #C19F86;
} */

@media (min-width: 1024px) {


.nav-item {
  margin: 10px;
  color: white;
  font-size: 19px;
  font-weight: bold;
  font-family: "Bona Nova SC", serif;
}
.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure the navbar is on top of other content */
}



.logo {
  position: static;
  transform: none;
  margin-left: 5;
  width: 200px;
  height: 105px;
  border-radius: 50%;
}

.navbar-collapse {
  justify-content: flex-end;
}
.scrolled {
  
  background: black; /* Darker background on scroll */
  height: 70px;
}


}

/* For Mobile */
@media (max-width: 896px) {
  .navbar {
  
  }

  .logo {
    position: static;
    transform: none;
    margin-left: 5;
    width: 100px;
    height: 55px;
    border-radius: 50%;
  }
}

.nav-anchor{
  text-decoration: none;
  color: inherit;
}



