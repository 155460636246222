.App{
position: relative;

}

/* Hide the default scrollbar */
/* body {
  overflow: hidden;
} */

body::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background: linear-gradient(
    260deg,
    rgba(162, 156, 151, 1) 9%,
    rgba(86, 81, 78, 1) 100%
  ); /* Background of the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around the scrollbar thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}
