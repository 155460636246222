@media (min-width: 1024px) {
  .main_div {
    /* padding-bottom: ; */
  }

  .productCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: linear-gradient(to right, #4b2e2e, #8b4513);
    transition: opacity 500s ease-in-out; /* Transition effect */
    height: 590px;
  }
  .move_right {
    font-size: 50px;
    color: white;
    cursor: pointer;
    font-weight: 200;
    opacity: 0.4;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
  }
  /* .product_img {
    align-items: center;
    justify-content: space-between;
} */
  .product_img {
    background-color: transparent;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    width: 500px;
    align-items: center;
  }
  .nextIcon {
    /* margin-right: -76px; */
  }
  .dots {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    right: 750px;
    bottom: 0;
    cursor: pointer;
  }
}

/* For Mobile */
@media (max-width: 896px) {
  .main_div {
    /* padding-bottom: ; */
  }

  .productCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #4b2e2e, #8b4513);
    /* border-radius: 10px; */
    /* margin-top: 10px; */
    /* padding: 0px 10px 10px 10px; */
    /* padding-top: 200px; */
    /* height: 450px; */
    transition: opacity 500s ease-in-out; /* Transition effect */
    height: 350px;
    position: relative;
  }
  .move_right {
    font-size: 50px;
    color: white;
    cursor: pointer;
    font-weight: 200;
    opacity: 0.4;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: white;
  }
  .productCard > div {
    background-color: transparent;
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 200px;
    align-items: center;
  }

  .nextIcon {
    display: none;
  }
  .dots {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    cursor: pointer;
    /* top: 731px; */
    /* bottom: 6px; */
    left: 99px;
    top: 313px;
  }
}
