@media (min-width: 1024px) {
  .contact-us {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 30vh;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
  }
  .heading {
    display: flex;
    flex-direction: column;
    color: white;
    margin-left: 40px;
    margin-top: 15px;
  }
  .contact_desc {
    font-size: 19px;
    line-height: 1.5;
    color: white;
    padding-top: 10px;
    margin: 10px 0;
    white-space: pre-wrap; /* Preserve whitespace */
    word-wrap: break-word; /* Break words as necessary */
    max-width: 40ch;
    word-spacing: 8px;
    /* font-family: "PT Sans", sans-serif; */
    /* font-weight: bold; */
  }
  .contact-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: center; */
    gap: 4.1rem;
    /* margin-left: 100px; */
    margin-right: 400px;
    margin-top: 90px;
    margin-left: 100px;
  }

  .contact-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.2rem;
  }

  .contact-item i {
    font-size: 30px;
  }
  .contact_text {
    display: flex;
    flex-direction: column;
    color: white;
  }
  .link {
    text-decoration: none;
    color: blanchedalmond;
    font-weight: bold;
  }
  .social-media {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    color: white;
  }
  .social-media i {
    font-size: 16px;
  }
}

/* For Mobile */
@media (max-width: 896px) {
  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 65vh;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .heading {
    display: flex;
    flex-direction: column;
    color: white;
    margin-left: 20px;
  }
  .title {
    text-align: center;
    margin-top: 15px;
  }
  .contact_desc {
    font-size: 14px;
    line-height: 1.5;
    color: white;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 45ch;
    word-spacing: 5px;
    /* font-family: "PT Sans", sans-serif; */
    /* font-weight: bold; */
    text-align: center;
  }
  .contact-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* justify-content: center; */

    /* margin-left: 100px; */

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .contact-item:nth-child(1)
 {
    grid-column: 1; /* First column */
    margin-left: 16px;
  }
  .contact-item:nth-child(2) {
    grid-column: 2; /* First column */
  }

  .contact-item:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
    margin-right: 131px   
  }

  .contact-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.2rem;
  }

  .contact-item i {
    font-size: 30px;
  }
  .contact_text {
    display: flex;
    flex-direction: column;
    color: white;
  }
  .link {
    text-decoration: none;
    color: blanchedalmond;
    font-weight: bold;
  }
  .social-media {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    color: white;
  }
  .social-media i {
    font-size: 16px;
  }
  
  .socials{
    margin-left: 70px;
  }
}
